import { DTO, Nullable } from '@/type-utils';
import type {
  ICMContentData,
  ICMItem,
  ICMNavigationPath,
  ICMPicture,
  ICMPlacement,
  ICMTarget,
  ICMTargetDownload,
  ICMTargetExternalLink,
  ICMTeaserOverlayStyles,
  ICMTeaserTarget,
  ICMVideo
} from '../../../isomorphic/deprecated/ContentService/coremedia-output-data-types';
import type { ILink, IPlacement, ISettings, IVideoSettings } from '..';
import type { IContentImage } from '../../Media/ContentImage';

import type { IContentVideo } from '../../Media/ContentVideo';
import type { TrackModel } from '../../Media/Track';
import { TrackKinds } from '../../Media/Track';
import CMSContentTypes from '../CMSContentTypes';
import ContentItemModel from '../ContentItemModel';
import ContentModel from '../ContentModel';
import { ContentPositionX, ContentPositionY } from '../Interfaces/ISettings';
import { siteCached } from '../../../utils/siteCached/siteCached';
import ConfigurationService from '../../../isomorphic/ConfigurationService/ConfigurationService';
import { Config } from '../../../isomorphic/ConfigurationService';

/**
 * This builds our content Models from Coremedia data.
 * It will be moved to the Service after the service overhaul.
 * This will work for one page.
 */
export default class ContentFactory {
  private _data: ICMContentData;
  private _placements: Array<IPlacement>;

  /** The output model of this factory. */
  public readonly contentModel: ContentModel;

  /**
   * Gets the baseurl for Coremedia as a string.
   * @returns A string representing the base url for Coremedia.
   */
  @siteCached
  private get baseURL(): string {
    return ConfigurationService.getConfig('coremedia').getSetting('baseUrl')
      .value;
  }

  /**
   * This gets the placments and then uses them to generate
   * the ContentModel.
   * @param data - The raw data queried from Coremedia.
   */
  public constructor(data: ICMContentData) {
    this._data = data;
    this._placements = this.getPlacements(data?.grid?.placements ?? []);

    const pageTitle =
      this._data?.htmlTitle !== '' ? this._data?.htmlTitle : null;
    const pageDescription =
      this._data?.htmlDescription !== '' ? this._data?.htmlDescription : null;

    this.contentModel = new ContentModel({
      id: this._data?.id ?? '',
      name: this._data?.title ?? '',
      pageTitle,
      pageDescription,
      seoText: (this._data?.settings?.seoText as string) ?? null,
      segment: this._data?.segment ?? '',
      coveoSlug: this._data?.settings?.slug as string,
      placements: this._placements
    });
  }

  /**
   * Generates the placements for the new content model.
   * @param rawPlacements - List of raw content placements.
   * @returns The new formated placments for the model.
   */
  private getPlacements(rawPlacements: Array<ICMPlacement>): Array<IPlacement> {
    const placements: Array<IPlacement> = [];
    rawPlacements?.forEach((placement) => {
      placements.push({
        name: placement.name,
        items: this.getItems(placement.items)
      });
    });
    return placements;
  }

  /** Generates the items needed for the new content model. Switch statement will
   * return the arrays of items, images, videos, and links depending on the __typename
   * property of the {@link ICMItem}.
   * @param item - ICMItem.
   * @returns object { modelItems, modelImages, modelVideos, modelLinks } for the model.
   */
  private getItemContent(item: ICMItem): {
    modelItems: Array<ICMItem>;
    modelImages: Array<ICMPicture>;
    modelVideos: Array<ICMVideo>;
    modelLinks: Nullable<Array<ILink>>;
    viewType: string | undefined;
  } {
    let modelItems: Array<ICMItem> = [];
    let modelImages: Array<ICMPicture> = [];
    let modelVideos: Array<ICMVideo> = [];
    let modelLinks: Nullable<Array<ILink>>;
    let viewType = item.viewtype;

    switch (item.__typename) {
      /** Handles teasers. */
      case CMSContentTypes.Teaser:
        modelItems = item.items as Array<ICMItem>;
        modelImages = item.pictures as Array<ICMPicture>;
        modelVideos = item.videos as Array<ICMVideo>;
        viewType = viewType ?? 'content-block';
        break;

      case CMSContentTypes.Collection:
        /**
         * Logic to handle images and videos attatched directly to a collection
         * since they are normally placed among the items.
         */
        modelItems = item.items?.filter(
          (val) => val.__typename !== CMSContentTypes.Image
        ) as Array<ICMItem>;
        modelImages = item.items?.filter(
          (val) => val.__typename === CMSContentTypes.Image
        ) as Array<ICMPicture>;
        modelVideos = item.items?.filter(
          (val) => val.__typename === CMSContentTypes.Video
        ) as Array<ICMVideo>;
        /** This makes the videos teaseable. */
        modelVideos = modelVideos.map((video) => {
          const videoModel = this.getItems([video])[0];
          return {
            ...video,
            pictures: videoModel.images,
            videos: videoModel.videos
          };
        }) as Array<ICMVideo>;
        viewType = viewType ?? 'collection';
        break;

      case CMSContentTypes.Link:
        /** This handles for external links and essentially makes them teasable. */
        modelLinks = [
          {
            text: item.teaserTitle ?? '',
            href: item.url ?? '',
            isCTA: false
          }
        ];
        break;

      case CMSContentTypes.Channel:
        /** This handles for pages used as links in the footer. */
        modelLinks = [
          {
            text: item.teaserTitle ?? '',
            href: this.getPathString(item.navigationPath ?? []),
            isCTA: false
          }
        ];
        break;

      case CMSContentTypes.Video:
        /** This handles videos. */
        modelImages = item.pictures as Array<ICMPicture>;
        modelVideos = item.videos as Array<ICMVideo>;
      // eslint-disable-next-line no-fallthrough -- Allow viewType to fall through for both videos and images. This will always be null on plain Video/Img items.
      case 'CMPictureImpl':
        viewType = 'content-media';
        break;

      default:
        break;
    }
    return { modelItems, modelImages, modelVideos, modelLinks, viewType };
  }

  /**
   * Gets the items recursively off the raw data.
   * @param rawItems - Raw items off the CoreMedia placement data.
   * @returns A list of item models on a placement.
   */
  private getItems(rawItems: Array<ICMItem>): Array<ContentItemModel> {
    const items: Array<ContentItemModel> = [];
    rawItems?.forEach((item) => {
      const { modelItems, modelImages, modelVideos, modelLinks, viewType } =
        this.getItemContent(item);

      items.push(
        ContentItemModel.from({
          id: item?.id ?? '',
          viewType: viewType ?? '',
          items: this.getItems(modelItems as Array<ICMItem>),
          title: item.teaserTitle,
          text: item.teaserText,
          settings: this.getSettings(item.teaserOverlaySettings!),
          links: modelLinks ?? this.getLinks(item.teaserTargets!),
          images: this.getImages(modelImages as Array<ICMPicture>),
          videos: this.getVideos(modelVideos as Array<ICMVideo>),
          custom: item.settings,
          type: item.__typename as CMSContentTypes,
          html: item.html
        })
      );
    });
    return items;
  }

  /**
   * This gets the links from the raw data.
   * @param targets - CoreMedia target data.
   * @returns Formatted CTA data.
   */
  private getLinks(targets: Array<ICMTeaserTarget>): Array<ILink> {
    const links: Array<ILink> = [];
    targets?.forEach((target) => {
      links.push({
        text: target.callToActionText,
        href: this.getHrefFromTarget(target.target),
        isCTA: target.callToActionEnabled
      });
    });

    return links;
  }

  /**
   * Gets images from the raw data.
   * @param pictures - Raw CoreMedia Picture data.
   * @returns Formatted list of ContentImages.
   */
  private getImages(pictures: Array<ICMPicture>): Array<IContentImage> {
    const images: Array<IContentImage> = [];
    pictures?.forEach((picture) => {
      images.push({
        caption: picture.title,
        uuid: picture.id,
        alt: picture.alt,
        urlTemplate: `${this.baseURL}${picture.uriTemplate}`
      });
    });

    return images;
  }

  // TODO: These get methods should be moved to a separate file.
  /**
   * Gets videos from the raw data.
   * @param videos - Raw CoreMedia Video data.
   * @returns Formatted list of ContentImages.
   */
  private getVideos(videos: Array<ICMVideo>): Array<IContentVideo> {
    const _videos = videos?.reduce<Array<IContentVideo>>((acc, video) => {
      const {
        id,
        detailText,
        title = '',
        pictures,
        dataUrl = '',
        videoSettings,
        related,
        teaserOverlaySettings,
        settings
      } = video;
      const { playerSettings } = (videoSettings as IVideoSettings) ?? {};
      const {
        autoplay = false,
        loop = false,
        muted = false,
        controls: hideControls = false
      } = playerSettings ?? {};
      /** Get tracks from related content */
      const tracks = related?.map((item) => {
        return {
          src: `${this.baseURL}${item.fullyQualifiedUrl}`,
          kind: (item.teaserText as TrackKinds) ?? TrackKinds.Captions,
          srclang: item.locale ?? 'en',
          label: (item.teaserTitle as TrackKinds) ?? TrackKinds.Captions
        };
      }) as Array<DTO<TrackModel>>;
      acc.push({
        uuid: id,
        title,
        alt: detailText,
        pictures: this.getImages(pictures as Array<ICMPicture>),
        autoplay,
        loop,
        muted,
        hideControls,
        dataUrl: dataUrl ?? '',
        tracks,
        overlaySettings: this.getSettings(teaserOverlaySettings!),
        custom: settings
      });
      return acc as Array<IContentVideo>;
    }, []);
    return _videos;
  }

  /**
   * Get some abstract settings from the raw data.
   * @param rawSettings - These are custom settings coming from the data.
   * @returns - Abstracted teaser settings in content.
   */
  private getSettings(rawSettings: ICMTeaserOverlayStyles): ISettings {
    let positionX: ContentPositionX;
    switch (rawSettings.positionX) {
      case 50: {
        positionX = ContentPositionX.Right;
        break;
      }
      case -50: {
        positionX = ContentPositionX.Left;
        break;
      }
      default: {
        positionX = ContentPositionX.Center;
        break;
      }
    }

    let positionY: ContentPositionY;
    switch (rawSettings.positionY) {
      case 50: {
        positionY = ContentPositionY.Top;
        break;
      }
      case -50: {
        positionY = ContentPositionY.Bottom;
        break;
      }
      default: {
        positionY = ContentPositionY.Center;
        break;
      }
    }

    return {
      style: rawSettings.style,
      enabled: rawSettings.enabled,
      positionX,
      positionY,
      width: rawSettings.width,
      __typename: rawSettings.__typename
    };
  }

  /**
   * This takes a navigationPath from a content page and turns it into a relative href.
   * @param paths - The navigationPath of a page.
   * @returns A string that holds the whole path for link generation.
   */
  private getPathString(paths: Array<ICMNavigationPath>): string {
    paths.shift();
    return paths.map((path) => path.segment).join('/');
  }

  /**
   * This takes a target and determines where to pull the href depending on the type.
   * @param target - The target of a link.
   * @returns String  - that holds the href.
   */
  private getHrefFromTarget(target: ICMTarget): string {
    const _type = target?.__typename ?? '';
    switch (_type) {
      case CMSContentTypes.Link: {
        const _target = target as ICMTargetExternalLink;
        return _target.url ?? target?.segment ?? '';
      }
      case CMSContentTypes.Download: {
        const _target = target as ICMTargetDownload;
        return `${this.baseURL}${_target.fullyQualifiedUrl}`;
      }
      case CMSContentTypes.Channel: {
        return this.getPathString(target?.navigationPath ?? []);
      }
      default: {
        return target?.segment ?? '';
      }
    }
  }
}
