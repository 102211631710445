import type {
  ICMNavigationData,
  ICMNavigationContentData,
  ICMNavigationFooterContentData,
  ICMContentData
} from '../../../isomorphic/deprecated/ContentService/coremedia-output-data-types';
import ContentModel from '../ContentModel';
import ContentItemModel from '../ContentItemModel';
import NavigationContentModel from '../NavigationContentModel';
import { IHeaderCategory } from '../Interfaces/IHeaderCategory';
import { ICategoryBlock } from '../Interfaces/ICategoryBlock';
import { IContentLink } from '../Interfaces/IContentLink';
import CMSPlacementTypes from '../CMSPlacementTypes';

import ContentFactory from './ContentFactory';
import { IContent } from '../Interfaces/IContent';
import { INavigationContent } from '../Interfaces/INavigationContent';

/**
 * This is an intermediate data type used by this factory to turn footer
 * data into generic Content, which will be turned into footer content.
 */
export interface IFooterIntermediateContent {
  /** The column header segment, can be used as a link and an id. */
  segment: string;
  /** The title is what is rendered as the column header. */
  title: string;
  /** The sub-items that appear under the column header and link to other pages. */
  items: Array<ContentItemModel>;
}

const emptyContent = {
  id: '',
  name: '',
  placements: [],
  segment: '',
  pageTitle: '',
  pageDescription: '',
  coveoSlug: '',
  seoText: null,
  data: {}
} as IContent;

const emptyNavigationContent = {
  headerCategories: [],
  globalContent: emptyContent
} as INavigationContent;

/**
 * This constructs the navigationModel from specifically
 * CoreMedia data.
 */
export default class NavigationFactory {
  private headerCategories: Array<IHeaderCategory>;
  private globalContent: ContentModel;

  /** The model produced by this factory. It contains data to construct all global content. */
  public readonly contentModel: NavigationContentModel;

  /**
   * NavigationContent creator, takes in CoreMedia data and outputs a formatted model.
   * @param data - Raw content data organized into headings.
   */
  public constructor(data: ICMNavigationData) {
    this.headerCategories = data.navigationTree
      ? this.getHeaderCategories(data.navigationTree)
      : [];
    this.globalContent = new ContentFactory(data.globalData).contentModel;

    this.contentModel = this.buildNavigationContentModel();
  }

  /**
   * Constructs the object that will be used to create the model.
   * @returns New NavigationContentModel.
   */
  private buildNavigationContentModel(): NavigationContentModel {
    const navigationObject = {
      headerCategories: this.headerCategories,
      globalContent: this.globalContent
    };

    return NavigationContentModel.from(navigationObject);
  }

  /**
   * Pass in a first level category and get back its children in link form.
   * This basically just digs through the data looping over the children properties
   * while maintaining the current level of navigation.
   * @param l1Category - A first level category.
   * @returns A list of CategoryBlocks for rendering navigation.
   */
  private getL1Blocks(
    l1Category: ICMNavigationContentData
  ): Array<ICategoryBlock> {
    const l1Items = [] as Array<IContentLink>;
    const blocks: Array<ICategoryBlock> = [];

    l1Category.children?.forEach((l2Category) => {
      /** This means that it has subcategories to loop over. */
      const isCategory = l2Category.__typename === 'CMAbstractCategoryImpl';
      if (isCategory) {
        blocks.push({
          id: l2Category.segment,
          /** Deprecated config for the Megamenu composable component. */
          config: {
            megamenu: {
              columns: 4
            }
          },
          /** Generates more links as it decends down to a third layer of children. */
          links: [
            {
              id: l2Category.segment,
              label: l2Category.title!,
              links: l2Category.children!.map((l3Category) => ({
                id: l3Category.segment,
                /**
                 * Uses the category segment to generate the hrefs looking up the chain to
                 * see the first and second layer.
                 */
                href:
                  l3Category.segment === 'view-all'
                    ? `/${l1Category.segment}`
                    : `/${l1Category.segment}/${l2Category.segment}/${l3Category.segment}`,
                label: l3Category.title!
              }))
            }
          ]
        });
      }

      /** Has no children so just pushes itself as a link. */
      l1Items.push({
        id: l2Category.segment,
        href: l2Category.segment,
        label: l2Category.title!
      });
    });

    if (l1Items.length && !blocks.length) {
      blocks.push({
        id: l1Category.segment,
        config: {
          megamenu: {
            columns: 1
          }
        },
        links: l1Items
      });
    }

    return blocks;
  }

  /**
   * Gets the header categories from the raw data. It loops over the children
   * and gets the children that have the isFooterRoot custom property. Then it
   * loops through these categories and converts them into IHeaderCategory.
   * These links have a blocks property, which contain levels to be handled
   * above.
   * @param data - Raw content data holding the navigation query response.
   * @returns A list of header categories for rendering the navigation.
   */
  private getHeaderCategories(
    data: ICMNavigationContentData | null
  ): Array<IHeaderCategory> {
    if (!data) {
      return [];
    }

    const navigationData: Array<IHeaderCategory> = data!
      .children! /** Assembles category object from each footer category. */
      .map((l1Category) => {
        const l1Prefix = '/';

        return {
          id: l1Category.segment,
          label: l1Category.title!,
          href: `${l1Prefix}${l1Category.segment}`,

          blocks: this.getL1Blocks(l1Category)
        };
      });
    return navigationData || [];
  }

  /**
   * Constructs the footer categories from raw Content data.
   * @param data - Raw data from Coremedia that holds the footer.
   * @returns The the category block to be used for the main footer links.
   */
  private getFooterCategories(
    data: ICMNavigationFooterContentData | null
  ): ICategoryBlock {
    if (!data) {
      return { links: [] } as ICategoryBlock;
    }

    const footerPages: Array<IFooterIntermediateContent> = data.children!.map(
      (child: ICMContentData) => {
        const { contentModel } = new ContentFactory(child);
        return {
          segment: contentModel.segment,
          title: contentModel.pageTitle,
          items: contentModel.getPlacement(CMSPlacementTypes.FooterLinks)?.items
        } as IFooterIntermediateContent;
      }
    );

    const footerCategories: Array<IContentLink> = footerPages.map(
      (page): IContentLink => ({
        id: page.segment,
        label: page.title,
        /** Gets child links one leve deep. */
        links: page?.items?.map(
          (item): IContentLink => ({
            id: item.title!,
            label: item.title!,
            // To be replaced by a URL util. Handles both relative and absolute
            // links from Coremedia.
            href: `${item.link!.href.indexOf('https:') > -1 ? '' : '/'}${
              item.link!.href
            }`
          })
        )
      })
    );

    return { links: footerCategories || [] } as ICategoryBlock;
  }
}
