import {
  INavigationContent,
  ContentPositionX,
  ContentPositionY,
  CMSContentTypes
} from '../../../../models/Content';

// Mocks the navigation content from coremedia
// This was used to during development to better understand design needs
// Not this is used for StoryBook
const headerCategories: INavigationContent['headerCategories'] = [
  {
    id: 'women',
    label: 'Women',
    href: '/womens-view-all/',
    blocks: [
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Footwear',
            links: [
              {
                id: '0',
                href: '/womens-view-all/',
                label: 'View All'
              },
              {
                id: '1',
                href: '/womens-new-arrivals/',
                label: "What's New"
              },
              {
                id: '2',
                href: '/womens-best-sellers/',
                label: 'Best Sellers'
              },
              {
                id: '3',
                href: '/womens-sidewalk-surfers/',
                label: 'Sidewalk Surfers'
              },
              {
                id: '4',
                href: '/womens-shoes/',
                label: 'Shoes'
              },
              {
                id: '5',
                href: '/womens-slippers/',
                label: 'Slippers'
              },
              {
                id: '6',
                href: '/womens-sandals/',
                label: 'Sandals'
              },
              {
                id: '7',
                href: '/womens-flip-flops/',
                label: 'Flip Flops'
              },
              {
                id: '8',
                href: '/slings/',
                label: 'Yoga Slings'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Featured',
            links: [
              {
                id: '0',
                href: '/25anniversary/',
                label: '25th Anniversary'
              },
              {
                id: '1',
                href: '/collaborations/',
                label: 'Collaborations'
              },
              {
                id: '2',
                href: '/sanuk-x-grateful-dead-collaboration/',
                label: 'Grateful Dead Collection'
              },
              {
                id: '3',
                href: '/sustainasole/',
                label: 'Sustainasole'
              },
              {
                id: '4',
                href: '/cozy-vibes-collection/',
                label: 'Cozy Vibes Collection'
              },
              {
                id: '5',
                href: '/soft-top-comfort/',
                label: 'Soft Top Comfort'
              },
              {
                id: '6',
                href: '/womens-yoga-mat-comfort/',
                label: 'Cosmic Yoga Mat'
              },
              {
                id: '7',
                href: '/you-got-my-back/',
                label: 'You Got My Back'
              },
              {
                id: '8',
                href: '/pride/',
                label: 'Pride Collection'
              },
              {
                id: '9',
                href: '/pair-o-dice/',
                label: 'Pair O Dice Collection'
              },
              {
                id: '10',
                href: '/womens-sidewalk-surfers-donna/',
                label: 'Donna Collection'
              },
              {
                id: '11',
                href: '/chill/',
                label: 'Chill Collection'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Sustainability',
            links: [
              {
                id: '0',
                href: '/women-vegan-styles/',
                label: 'Vegan'
              },
              {
                id: '1',
                href: '/hemp-footwear/',
                label: 'Hemp'
              },
              {
                id: '2',
                href: '/womens-recycled-materials/',
                label: 'Recycled Materials'
              },
              {
                id: '3',
                href: '/womens-natural-materials/',
                label: 'Natural Materials'
              },
              {
                id: '4',
                href: '/womens-washable-styles/',
                label: 'Washable'
              },
              {
                id: '5',
                href: '/leather-sandals-and-shoes/',
                label: 'Responsible Leather'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Activity',
            links: [
              {
                id: '0',
                href: '/womens-flip-flops/',
                label: 'Beach'
              },
              {
                id: '1',
                href: '/womens-activity-travel/',
                label: 'Travel'
              },
              {
                id: '2',
                href: '/womens-activity-around-town/',
                label: 'Around Town'
              },
              {
                id: '3',
                href: '/womens-chill-collection/',
                label: "Cozy Chillin'"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'men',
    label: 'Men',
    href: '/mens-view-all/',
    blocks: [
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Footwear',
            links: [
              {
                id: '0',
                href: '/mens-view-all/',
                label: 'View All'
              },
              {
                id: '1',
                href: '/mens-new-arrivals/',
                label: "What's New"
              },
              {
                id: '2',
                href: '/mens-best-sellers/',
                label: 'Best Sellers'
              },
              {
                id: '3',
                href: '/mens-sidewalk-surfers/',
                label: 'Sidewalk Surfers'
              },
              {
                id: '4',
                href: '/mens-shoes/',
                label: 'Shoes'
              },
              {
                id: '5',
                href: '/mens-slippers/',
                label: 'Slippers'
              },
              {
                id: '6',
                href: '/mens-flip-flops/',
                label: 'Flip Flops'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Featured',
            links: [
              {
                id: '0',
                href: '/25anniversary/',
                label: '25th Anniversary'
              },
              {
                id: '1',
                href: '/collaborations/',
                label: 'Collaborations'
              },
              {
                id: '2',
                href: '/soft-top-comfort/',
                label: "Donavon's Secret Stache"
              },
              {
                id: '3',
                href: '/sanuk-x-grateful-dead-collaboration/',
                label: 'Grateful Dead Collection'
              },
              {
                id: '4',
                href: '/sustainasole/',
                label: 'Sustainasole'
              },
              {
                id: '5',
                href: '/cozy-vibes-collection/',
                label: 'Cozy Vibes Collection'
              },
              {
                id: '6',
                href: '/soft-top-comfort/',
                label: 'Soft Top Comfort'
              },
              {
                id: '7',
                href: '/mens-sandals-beer-cozy/',
                label: 'Beer Cozy Comfort'
              },
              {
                id: '8',
                href: '/you-got-my-back/',
                label: 'You Got My Back'
              },
              {
                id: '9',
                href: '/pride/',
                label: 'Pride Collection'
              },
              {
                id: '10',
                href: '/mens-sidewalk-surfers-vagabond/',
                label: 'Vagabonds'
              },
              {
                id: '11',
                href: '/chill/',
                label: 'Chill Collection'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Sustainability',
            links: [
              {
                id: '0',
                href: '/men-vegan-styles/',
                label: 'Vegan'
              },
              {
                id: '1',
                href: '/hemp-footwear/',
                label: 'Hemp'
              },
              {
                id: '2',
                href: '/mens-recycled-materials/',
                label: 'Recycled Materials'
              },
              {
                id: '3',
                href: '/mens-natural-materials/',
                label: 'Natural Materials'
              },
              {
                id: '4',
                href: '/mens-washable-styles/',
                label: 'Washable'
              },
              {
                id: '5',
                href: '/leather-sandals-and-shoes/',
                label: 'Responsible Leather'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Activity',
            links: [
              {
                id: '0',
                href: '/mens-flip-flops/',
                label: 'Beach'
              },
              {
                id: '1',
                href: '/mens-activity-travel/',
                label: 'Travel'
              },
              {
                id: '2',
                href: '/mens-activity-around-town/',
                label: 'Around Town'
              },
              {
                id: '3',
                href: '/mens-chill-collection/',
                label: "Cozy Chillin'"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'kids',
    label: 'Kids',
    href: '/kids-view-all/',
    blocks: [
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Footwear',
            links: [
              {
                id: '0',
                href: '/kids-view-all/',
                label: 'View All'
              },
              {
                id: '1',
                href: '/kids-sidewalk-surfers/',
                label: 'Sidewalk Surfers'
              },
              {
                id: '2',
                href: '/kids-sandals/',
                label: 'Sandals'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Featured',
            links: [
              {
                id: '0',
                href: '/little-kids/',
                label: 'Little Kids (1-5 Years)'
              },
              {
                id: '1',
                href: '/big-kids/',
                label: 'Big Kids (6-12 Years)'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Sustainability',
            links: [
              {
                id: '0',
                href: '/kids-vegan-styles/',
                label: 'Vegan'
              },
              {
                id: '1',
                href: '/kids-washable-styles/',
                label: 'Washable'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Activity',
            links: [
              {
                id: '0',
                href: '/kids-activity-beach/',
                label: 'Beach'
              },
              {
                id: '1',
                href: '/kids-activity-travel/',
                label: 'Travel'
              },
              {
                id: '2',
                href: '/kids-play/',
                label: 'Play'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'all',
    label: 'All',
    href: '/all/',
    blocks: [
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Footwear',
            links: [
              {
                id: '0',
                href: '/whats-new/',
                label: "What's New"
              },
              {
                id: '1',
                href: '/bestsellers/',
                label: 'Best Sellers'
              },
              {
                id: '2',
                href: '/sidewalk-surfers/',
                label: 'Sidewalk Surfers'
              },
              {
                id: '3',
                href: '/slippers/',
                label: 'Slippers'
              },
              {
                id: '4',
                href: '/sandals/',
                label: 'Sandals'
              },
              {
                id: '5',
                href: '/flip-flops/',
                label: 'Flip Flops'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Featured',
            links: [
              {
                id: '0',
                href: '/25anniversary/',
                label: '25th Anniversary'
              },
              {
                id: '1',
                href: '/collaborations/',
                label: 'Collaborations'
              },
              {
                id: '2',
                href: '/soft-top-comfort/',
                label: "Donavon's Secret Stache"
              },
              {
                id: '3',
                href: '/sanuk-x-grateful-dead-collaboration/',
                label: 'Grateful Dead Collection'
              },
              {
                id: '4',
                href: '/sustainasole/',
                label: 'Sustainasole'
              },
              {
                id: '5',
                href: '/cozy-vibes-collection/',
                label: 'Cozy Vibes Collection'
              },
              {
                id: '6',
                href: '/soft-top-comfort/',
                label: 'Soft Top Comfort'
              },
              {
                id: '7',
                href: '/yoga-mat-comfort/',
                label: 'Cosmic Yoga Mat'
              },
              {
                id: '8',
                href: '/mens-sandals-beer-cozy/',
                label: 'Beer Cozy Comfort'
              },
              {
                id: '9',
                href: '/you-got-my-back/',
                label: 'You Got My Back'
              },
              {
                id: '10',
                href: '/pride/',
                label: 'Pride Collection'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Shop Sustainably',
            links: [
              {
                id: '0',
                href: '/vegan-footwear/',
                label: 'Vegan'
              },
              {
                id: '1',
                href: '/hemp-footwear/',
                label: 'Hemp'
              },
              {
                id: '2',
                href: '/recycled-materials/',
                label: 'Recycled Materials'
              },
              {
                id: '3',
                href: '/natural-materials/',
                label: 'Natural Materials'
              },
              {
                id: '4',
                href: '/leather-sandals-and-shoes/',
                label: 'Responsible Leather'
              },
              {
                id: '5',
                href: '/all-washable-styles/',
                label: 'Washable'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Activity',
            links: [
              {
                id: '0',
                href: '/chill/',
                label: "Cozy Chillin'"
              },
              {
                id: '1',
                href: '/flip-flops/',
                label: 'Beach'
              },
              {
                id: '2',
                href: '/best-travel-shoes/',
                label: 'Travel'
              },
              {
                id: '3',
                href: '/bestsellers/',
                label: 'Around Town'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'sale',
    label: 'Sale',
    href: '/last-call-sale/',
    blocks: [
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Sale Categories',
            links: [
              {
                id: '0',
                href: '/last-call-sale/',
                label: 'View All Sale'
              },
              {
                id: '1',
                href: '/womens-last-call-sale/',
                label: 'Womens Sale'
              },
              {
                id: '2',
                href: '/mens-last-call-sale/',
                label: 'Mens Sale'
              },
              {
                id: '3',
                href: '/kids-last-call-sale/',
                label: 'Kids Sale'
              },
              {
                id: '4',
                href: '/sandals-bundle/',
                label: 'Bundle and Save'
              },
              {
                id: '5',
                href: '/sanuk-student-discount/',
                label: 'Student Discount'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'brand',
    label: 'Brand',
    href: '/brand/',
    blocks: [
      {
        id: '1',
        links: [
          {
            id: '1',
            label: '',
            links: [
              {
                id: '0',
                href: '/brand/',
                label: 'Our Story'
              },
              {
                id: '1',
                href: '/our-ambassadors.html',
                label: 'Ambassadors'
              },
              {
                id: '2',
                href: '/surfrider.html',
                label: 'Surfrider Foundation'
              },
              {
                id: '3',
                href: '/corporate-responsibility.html',
                label: 'Doing Good'
              },
              {
                id: '4',
                href: '/blog',
                label: 'Blog'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'sustainability',
    label: 'Sustainability',
    href: '/sustainability/',
    blocks: [
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Shop Sustainably',
            links: [
              {
                id: '0',
                href: '/vegan-footwear/',
                label: 'Vegan'
              },
              {
                id: '1',
                href: '/hemp-footwear/',
                label: 'Hemp'
              },
              {
                id: '2',
                href: '/recycled-materials/',
                label: 'Recycled Materials'
              },
              {
                id: '3',
                href: '/natural-materials/',
                label: 'Natural Materials'
              },
              {
                id: '4',
                href: '/leather-sandals-and-shoes/',
                label: 'Responsible Leather'
              },
              {
                id: '5',
                href: '/all-washable-styles/',
                label: 'Washable'
              }
            ]
          }
        ]
      },
      {
        id: '1',
        links: [
          {
            id: '1',
            label: 'Sustainability Journey',
            links: [
              {
                id: '0',
                href: '/our-perspective.html',
                label: 'Our Sustainability Journey'
              },
              {
                id: '1',
                href: '/environment.html',
                label: 'Our Environment'
              },
              {
                id: '2',
                href: '/materials.html',
                label: 'Our Materials'
              },
              {
                id: '3',
                href: '/people.html',
                label: 'Our People'
              },
              {
                id: '4',
                href: '/culture-and-philanthropy.html',
                label: 'Our Philanthropy'
              },
              {
                id: '5',
                href: '/policies-and-principles.html',
                label: 'Our Policies And Principles'
              },
              {
                id: '6',
                href: '/on/demandware.static/-/Sites-SANUK-US-Library/default/dwb70dceda/Deckers-Commitment-to-Change.pdf',
                label: 'Our Commitment to DEI'
              }
            ]
          }
        ]
      }
    ]
  }
];

const globalContent: INavigationContent['globalContent'] = {
  id: '',
  name: '',
  seoText: '',
  pageTitle: 'Global Content',
  pageDescription: '',
  coveoSlug: '',
  segment: 'global',
  placements: [
    {
      name: 'marketing-banner',
      items: [
        {
          viewType: 'marketing-banner',
          items: [],
          title: 'All pages top marketing banner',
          text: '<div><p>Free Expedited Shipping for orders over $35</p></div>',
          custom: {
            isFooterRoot: null,
            backgroundColor: '#F78D14',
            textColor: null
          },
          links: [
            {
              text: 'Sale!',
              href: 'sale',
              isCTA: true
            }
          ],
          images: [],
          settings: {
            style: {
              cta: 'secondary',
              text: 'lightText',
              headline: 'lightText'
            },
            enabled: true,
            positionX: ContentPositionX.Center,
            positionY: ContentPositionY.Center,
            width: 50,
            __typename: 'TeaserOverlaySettings'
          },
          type: CMSContentTypes.Teaser
        }
      ]
    },
    {
      name: 'footer-banner',
      items: [
        {
          viewType: 'marketing-banner',
          items: [],
          title: 'marketing banner bottom',
          text: '<div><p>FREE SHIPPING OVER $35. FREE RETURNS</p></div>',
          custom: {
            isFooterRoot: null,
            backgroundColor: '#56A3D6',
            textColor: '#FFFF'
          },
          links: [],
          images: [
            {
              uuid: '56114',
              alt: 'S21-MAY-global-footerBanner',
              urlTemplate:
                'https://caas.uat.deckers.coremedia.cloud/caas/v1/media/56114/data/4888ff23c9f48a6b570db212c618b9cf/{cropName}/{width}/s21-may-global-footerbanner.jpg',
              aspectRatio: 'landscape_ratio4x3'
            }
          ],
          settings: {
            style: {
              cta: 'secondary',
              text: 'lightText',
              headline: 'lightText'
            },
            enabled: true,
            positionX: ContentPositionX.Center,
            positionY: ContentPositionY.Center,
            width: 50,
            __typename: 'TeaserOverlaySettings'
          },
          type: CMSContentTypes.Teaser
        }
      ]
    }
  ]
};

export const NAVIGATION_CONTENT: INavigationContent = {
  globalContent,
  headerCategories
};
