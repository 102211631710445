import { DTO, Nullable } from '@/type-utils';
import { siteCached } from '@/services/utils/siteCached/siteCached';

import {
  NavigationQuery,
  NavigationQuery_content_pageByPath
} from '@/services/serverless/integrations/CoremediaService/queries/__generated__/NavigationQuery';
import {
  GenericQuery,
  GenericQuery_content_pageByPath
} from '@/services/serverless/integrations/CoremediaService/queries/__generated__/GenericQuery';
import { TagNameQuery } from '@/services/serverless/integrations/CoremediaService/queries/__generated__/TagNameQuery';
import {
  ContentFactory,
  FragmentFactory,
  IContent,
  INavigationContent,
  NavigationFactory
} from '../../../models/Content';
import Service from '../../../Service';
import LoggerService from '../../LoggerService';

import ConfigurationService, { Config } from '../../ConfigurationService';

import I18NService from '../../I18NService';
import { PreviewService } from '../../PreviewService';

import ContentQueryHandler from './ContentQueryHandler';
import {
  ICMContentData,
  ICMItem,
  ICMNavigationContentData,
  ICMNavigationData,
  IContentPage
} from './coremedia-output-data-types';

import ContentServiceMock from './ContentServiceMock';

const emptyContent = {
  id: '',
  placements: [],
  segment: '',
  name: '',
  pageTitle: null,
  pageDescription: null,
  coveoSlug: '',
  seoText: null,
  data: {}
} as IContent;

const emptyNavigationContent = {
  headerCategories: [],
  footerCategories: {
    links: []
  },
  globalContent: emptyContent,
  footerContent: emptyContent
} as INavigationContent;

/**
 * Content service to normalize and handle the Coremedia integration.
 * @deprecated This service is deprecated and will be fundamentally changed in the future
 * to accomodate the ascendance of the BuilderService..
 */
export class ContentService extends Service {
  /**
   * The content config.
   * @returns A `Config<'content'>`.
   */
  @siteCached
  private get contentConfig(): Config<'content'> {
    return ConfigurationService.getConfig('content');
  }

  /**
   * The content config.
   * @returns A `Config<'coremedia'>`.
   */
  @siteCached
  private get coremediaConfig(): Config<'coremedia'> {
    return ConfigurationService.getConfig('coremedia');
  }

  /**
   * The menu config.
   * @returns A `Config<'menu'>`.
   */
  @siteCached
  private get menuConfig(): Config<'menu'> {
    return ConfigurationService.getConfig('menu');
  }

  /** Initializes the content service. */
  public constructor() {
    super();
  }

  /**
   * This is the main function used to get the raw content data in the StaticProps for the navigation
   * and global content items.
   * @returns Gets the raw navigation data.
   */
  private async getNavigationData(): Promise<ICMNavigationData> {
    const langLocale = I18NService.currentLocale.toString().toLowerCase();

    const homepageSegment =
      this.coremediaConfig.getSetting('segments.homepage').value;
    const navigationSegment = this.coremediaConfig.getSetting(
      'segments.navigation'
    ).value;
    const globalSegment =
      this.coremediaConfig.getSetting('segments.global').value;

    /**
     * This is a page that holds the header navigation in its
     * page navigation. This way the homepage decides URL segments
     * the navigation page decides how the header is constructed.
     */
    const navigationPath =
      `${homepageSegment}-${langLocale}/${navigationSegment}` as const;
    /**
     * This is a page the holds data that could be loaded on any page.
     * This is held in placements that can be accessed by any component that
     * wants to render that content.
     */
    const globalPath =
      `${homepageSegment}-${langLocale}/${globalSegment}` as const;

    const transformer = (
      data: NavigationQuery | GenericQuery
    ):
      | NavigationQuery_content_pageByPath
      | GenericQuery_content_pageByPath
      | null => data!.content?.pageByPath ?? null;

    // This is a flag to determine if we are using the CMS navigation values or the configured values.
    const isContentNavigation = !this.menuConfig.getSetting(
      'mainMenu.overrideCmsValues'
    ).value;
    const requestArray: Array<
      Promise<GenericQuery> | Promise<NavigationQuery>
    > = [ContentQueryHandler.getGenericContent(globalPath)];

    // If we are using the CMS navigation values we will get the navigation content.
    if (isContentNavigation) {
      requestArray.push(
        ContentQueryHandler.getNavigationContent(navigationPath)
      );
    }

    const rawData = await Promise.all(requestArray);

    const [globalData, navigationTree] = rawData.map((data) =>
      transformer(data)
    );

    /**
     * TODO: When we deal with caching we should handle it as John described in a comment:
     * "Rather than letting the page load as if everything is okay, I think our better
     * fail-safe here would be to cache the responses (except when in preview mode) and
     * use the cached responses, and use a stale while revalidate approach where we can
     * always use the stale value, and if we can't get a fresh value, log an error.
     * If there is no stale value (cached response) and the data did not come back
     * as expected from the service, then we should throw an error because it's an
     * irrecoverable problem.".
     */
    if (isContentNavigation && !navigationTree) {
      LoggerService.error('No navigation content retrieved from CoreMedia.');
    }

    const navigationData: ICMNavigationData = {
      navigationTree: navigationTree
        ? (navigationTree as ICMNavigationContentData)
        : null,
      globalData: globalData as ICMContentData
    };

    return navigationData;
  }

  /**
   * This gets raw content for an individual page.
   * @param [path] - The page path of the content. If empty it simply gets the root query, ie: the homepage.
   * @returns Gets a an object that holds all of a pages raw content for the page.
   */
  private async getPageData(path: string = ''): Promise<ICMContentData> {
    const langLocale = I18NService.currentLocale.toString().toLowerCase();

    const homepageSegment =
      this.coremediaConfig.getSetting('segments.homepage').value;

    const pagePath = `${homepageSegment}-${langLocale}/${path}` as const;

    const transformer = (
      data: NavigationQuery | GenericQuery
    ):
      | NavigationQuery_content_pageByPath
      | GenericQuery_content_pageByPath
      | null => data!.content?.pageByPath ?? null;

    const pageData = transformer(
      await ContentQueryHandler.getGenericContent(pagePath)
    );

    return pageData as ICMContentData;
  }

  /**
   * Used to get the full content given a page path and locale.
   * @param path - The page path of the content.
   * @param tagName - A tag to use instead of the page path.
   * @returns Returns the formatted page content.
   */
  public async getPageContent(
    path: Nullable<string> = null,
    tagName: Nullable<string> = null
  ): Promise<IContentPage> {
    const data = await Promise.all([
      this.getNavigationData(),
      !tagName && typeof path === 'string' ? this.getPageData(path) : null,
      tagName ? this.getContentByTagName(tagName) : null
    ]);

    const [navigationData, pathData, tagData] = data;
    const pageData = tagData ?? pathData;

    const pageContent = pageData
      ? new ContentFactory(pageData).contentModel.toDTO()
      : emptyContent;
    const navigationContent = new NavigationFactory(
      navigationData
    ).contentModel.toDTO();

    return {
      pageContent: pageContent as IContent,
      navigationContent: navigationContent as INavigationContent,
      revalidate: this.contentConfig.getSetting('revalidate').value
    };
  }

  /**
   * Gets the base content by tagname. This base content can be run
   * through the content factory to get back a page.
   * @param tag - The tagName you wish to target.
   * @returns Content as generic page data.
   */
  public async getContentByTagName(tag: string): Promise<ICMContentData> {
    const transformer = (data: TagNameQuery): Array<ICMContentData> =>
      (data?.content?.search?.result ?? []) as Array<ICMContentData>;

    const results = transformer(await ContentQueryHandler.getTagContent(tag));

    const pageData = results[0];

    return pageData as ICMContentData;
  }

  /**
   * Get preview fragment data. This is then intepreted to interpret what content
   * to render and where to render it.
   * @param contentId - Unique ID.
   * @param contentType - String representing the type, used to differentiate between
   * pages and non-pages. Realistically CMChannel and all others are the differetiation used here.
   * A CMChannel is essentially a full page.
   * @see {@link https://documentation.coremedia.com/cmcc-10/artifacts/2107/webhelp/coremedia-en/content/Content_Assets.html}
   * @returns A fully formed content page.
   */
  public async getFragmentContent(
    contentId: string,
    contentType: string
  ): Promise<IContentPage> {
    const isPage = contentType === 'CMChannel';

    let pageContent: DTO<IContent>;
    if (isPage) {
      const contentData = (await ContentQueryHandler.getIDContent(contentId))
        .content?.page as ICMContentData;

      pageContent =
        new ContentFactory(contentData).contentModel.toDTO() ?? emptyContent;
    } else {
      const contentData = (
        await ContentQueryHandler.getFragmentContent(contentId)
      ).content?.content as ICMItem;

      pageContent =
        new FragmentFactory(contentData).contentModel.toDTO() ?? emptyContent;
    }

    let navigationContent = emptyNavigationContent;

    if (!PreviewService.useBlankNavigation) {
      const navigationData = await this.getNavigationData();
      navigationContent = new NavigationFactory(
        navigationData
      ).contentModel.toDTO() as INavigationContent;
    }

    return {
      pageContent: pageContent as IContent,
      navigationContent: navigationContent as INavigationContent
    };
  }
}

export default ContentService.withMock(
  new ContentServiceMock(ContentService)
) as unknown as ContentService;
