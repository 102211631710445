import type {
  ICMContentData,
  ICMItem
} from '../../../isomorphic/deprecated/ContentService/coremedia-output-data-types';
import ContentModel from '../ContentModel';
import ContentFactory from './ContentFactory';

/**
 * Turns a ICMItem into a Content Model for use with the fragment preview.
 * A fragment is simply just any piece of specific content.
 * Fragments are single content items (that may include others as children)
 * that are acquired by contentId. Since contentId is not localizable this
 * is only useful in cases like preview where an outside source knows exactly
 * what content is being targeted. (for instance the preview url generator).
 */
export default class FragmentFactory {
  private _data: ICMItem;

  /** The output model of this factory. */
  public readonly contentModel: ContentModel;

  /**
   * This wraps the data in a blank page and then runs it through the content factory.
   * @param data - The raw data queried from Coremedia.
   */
  public constructor(data: ICMItem) {
    this._data = data;
    this.contentModel = new ContentFactory(this.wrapData(data)).contentModel;
  }

  /**
   * Wraps the data in a generic page and a generic placment. This
   * is for use in the fragment preview tool.
   * @param data - Data in the form of a raw item from fragment query.
   * @returns - A content model for rendering on a page.
   */
  private wrapData(data: ICMItem): ICMContentData {
    const PreviewPage = {
      id: '-1',
      title: 'Content Preview',
      segment: 'preview',
      navigationPath: [
        { segment: 'sanuk-ep-en-us', __typename: 'CMChanelImpl' }
      ],
      grid: {
        id: '-1',
        __typename: 'PageGrid',
        placements: [
          {
            id: '-1',
            name: 'preview-placement',
            __typename: 'PageGridPlacement',
            items: data ? [data] : []
          }
        ]
      },
      __typename: 'CMChanelImpl',
      pageDescription: 'A preview page for '
    };

    return PreviewPage;
  }
}
