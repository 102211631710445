import { EnvironmentService } from '../../EnvironmentService';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

import type {
  FooterQuery,
  FragmentPreviewQuery,
  GenericQuery,
  NavigationQuery,
  PageQuery,
  TagNameQuery
} from '@/services/serverless/integrations/CoremediaService';

import CoremediaService from '@/services/serverless/integrations/CoremediaService';

import { ContentQuery } from './ContentQuery';

import LoggerService from '../../LoggerService';
import { ContentQueryType } from './ContentQueryType';

/**
 * The possible query params used with the content api route.
 */
export type ContentQueryParam =
  | { id: string }
  | { tag: string }
  | { path: string };

/**
 * Handles retriving content data from the `CoremediaService` and the process of typing
 * data that comes back from the server.
 */
class ContentQueryHandler {
  private client: AxiosInstance;

  /** Initializes the axios client. */
  public constructor() {
    this.client = axios.create({
      baseURL: '/api/content'
    });
  }

  /**
   * If on the server, this gets content from the `CoremediaService`. If on the client
   * this gets content from the `content` api route, which ultimately goes through the
   * `CoremediaService`.
   * @param type - A {@link ContentQueryType} which sets the endpoint.
   * @param params - The params object that contains an id, tag, or path and uses it
   * to retrieve content.
   * @returns Response data from the `CoremediaService`.
   * @throws If the api route or the `CoremediaService` fails.
   */
  public async query(
    type: ContentQueryType,
    params: ContentQueryParam
  ): Promise<unknown> {
    try {
      if ((typeof window === "undefined")) {
        return CoremediaService.getContentByQueryParams({
          type,
          ...params
        } as ContentQuery);
      }

      const response: AxiosResponse<{ data: unknown }> = await this.client.get(
        type,
        {
          params
        }
      );

      return response.data;
    } catch (error) {
      LoggerService.error(
        `Coremedia was unable to retrieve content: ${(error as Error).message}`
      );

      return { content: null };
    }
  }

  /**
   * Gets generic content using a page path.
   * @param path - The full path of the content.
   * @returns `GenericQuery` data that represents a content page.
   */
  public async getGenericContent(path: string): Promise<GenericQuery> {
    const type = ContentQueryType.Generic;
    const params = { path };
    const data = await this.query(type, params);

    return data as GenericQuery;
  }

  /**
   * Gets page content using a tag.
   * @param tag - The tag that might be attatched to a page.
   * @returns `TagNameQuery` data that represents a content page.
   */
  public async getTagContent(tag: string): Promise<TagNameQuery> {
    const type = ContentQueryType.Tag;
    const params = { tag };
    const data = await this.query(type, params);

    return data as TagNameQuery;
  }

  /**
   * Gets page content using an id.
   * @param id - The fragment id that is unique to a piece of content.
   * @returns `PageQuery` data that represents a content page.
   */
  public async getIDContent(id: string): Promise<PageQuery> {
    const type = ContentQueryType.ID;
    const params = { id };
    const data = await this.query(type, params);

    return data as PageQuery;
  }

  /**
   * Gets fragment content using an id.
   * @param id - The fragment id that is unique to a piece of content.
   * @returns `FragmentPreviewQuery` data that represents a content page.
   */
  public async getFragmentContent(id: string): Promise<FragmentPreviewQuery> {
    const type = ContentQueryType.Fragment;
    const params = { id };
    const data = await this.query(type, params);

    return data as FragmentPreviewQuery;
  }

  /**
   * Gets navigation content using a path.
   * @param path - The full path for that content page.
   * @returns `NavigationQuery` data that represents a content page.
   */
  public async getNavigationContent(path: string): Promise<NavigationQuery> {
    const type = ContentQueryType.Navigation;
    const params = { path };
    const data = await this.query(type, params);

    return data as NavigationQuery;
  }

  /**
   * Gets footer link content using a path.
   * @param path - The full path for that content page.
   * @returns `FooterQuery` data that represents a content page.
   */
  public async getFooterLinkContent(path: string): Promise<FooterQuery> {
    const type = ContentQueryType.FooterLink;
    const params = { path };
    const data = await this.query(type, params);

    return data as FooterQuery;
  }
}

export default new ContentQueryHandler();
