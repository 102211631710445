import {
  IContent,
  ContentPositionX,
  ContentPositionY,
  CMSContentTypes
} from '../../../../models/Content';

export const PAGE_CONTENT: IContent = {
  id: '',
  name: '',
  pageTitle: 'Sanuk EP Homepage',
  pageDescription: '',
  seoText: '',
  coveoSlug: '',
  segment: 'sanuk-ep-en-us',
  placements: [
    {
      name: 'main',
      items: [
        {
          viewType: 'hero-banner',
          items: [
            {
              viewType: 'text-block',
              items: [],
              title: 'The Headline title goes here',
              text: '<div><p>The headline body content text goes here. Be creative =)</p></div>',
              custom: {
                isFooterRoot: null,
                backgroundColor: null,
                textColor: null
              },
              links: [
                {
                  text: 'SHOP NOW',
                  href: 'all',
                  isCTA: true
                }
              ],
              images: [],
              settings: {
                style: {
                  cta: 'secondary',
                  text: 'lightText',
                  headline: 'lightText'
                },
                enabled: true,
                positionX: ContentPositionX.Center,
                positionY: ContentPositionY.Center,
                width: 50,
                __typename: 'TeaserOverlaySettings'
              },
              type: CMSContentTypes.Teaser
            }
          ],
          title: 'The Headline title goes here',
          text: '<div><p>The headline body content text goes here. Be creative =)</p></div>',
          custom: {
            isFooterRoot: null,
            backgroundColor: '#424984',
            textColor: null
          },
          links: [
            {
              text: '',
              href: '',
              isCTA: true
            }
          ],
          images: [
            {
              uuid: '57472',
              alt: 'july20-desktop-hero-image',
              urlTemplate:
                'https://caas-preview.uat.deckers.coremedia.cloud/caas/v1/media/57472/data/bb3f55848b0a4d4c6ea8e18c95f863df/{cropName}/{width}/july20-desktop-hero-image.jpg',
              aspectRatio: 'landscape_ratio4x3'
            }
          ],
          settings: {
            style: {
              cta: 'secondary',
              text: 'lightText',
              headline: 'lightText'
            },
            enabled: true,
            positionX: ContentPositionX.Center,
            positionY: ContentPositionY.Center,
            width: 50,
            __typename: 'TeaserOverlaySettings'
          },
          type: CMSContentTypes.Collection
        },
        {
          viewType: 'text-block',
          items: [],
          title: 'Text Block Headline',
          text: '<div><p>This is the text-body for this text block. Click the button if you want to browse.</p></div>',
          custom: {
            isFooterRoot: null,
            backgroundColor: null,
            textColor: null
          },
          links: [
            {
              text: 'SHOP NOW',
              href: 'all',
              isCTA: true
            }
          ],
          images: [],
          settings: {
            style: {
              cta: 'primary',
              text: 'darkText',
              headline: 'primaryText'
            },
            enabled: true,
            positionX: ContentPositionX.Center,
            positionY: ContentPositionY.Center,
            width: 50,
            __typename: 'TeaserOverlaySettings'
          },
          type: CMSContentTypes.Teaser
        },
        {
          viewType: 'split-banner',
          items: [],
          title: 'Split Banner',
          text: '',
          custom: {
            isFooterRoot: null,
            backgroundColor: null,
            textColor: null
          },
          links: [
            {
              text: 'Shop All',
              href: 'all',
              isCTA: true
            }
          ],
          images: [
            {
              uuid: '50086',
              alt: '',
              urlTemplate:
                'https://caas-preview.uat.deckers.coremedia.cloud/caas/v1/media/50086/data/18eacb10ac36ddfa077be7389049b3d8/{cropName}/{width}/split-banner-image-1.jpg',
              aspectRatio: 'landscape_ratio4x3'
            },
            {
              uuid: '50088',
              alt: '',
              urlTemplate:
                'https://caas-preview.uat.deckers.coremedia.cloud/caas/v1/media/50088/data/f1e382548d24890606190a20ce12b5d1/{cropName}/{width}/split-banner-image-2.jpg',
              aspectRatio: 'landscape_ratio4x3'
            }
          ],
          settings: {
            style: {
              cta: 'secondary',
              text: 'darkText',
              headline: 'darkText'
            },
            enabled: true,
            positionX: ContentPositionX.Center,
            positionY: ContentPositionY.Center,
            width: 50,
            __typename: 'TeaserOverlaySettings'
          },
          type: CMSContentTypes.Teaser
        }
      ]
    }
  ]
};
