import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { dateFromFormattedDateString } from '@/utils/date-utils';
import Service from '../../Service';
import { Nullable } from '@/type-utils';

/**
 * Provides information relating to preview. The main examples are Content Preview and NEXT Preview.
 */
class PreviewService extends Service {
  private _useBlankNavigation: Nullable<boolean>;
  private _previewDate: Nullable<Date>;
  private _isPreview: boolean;

  /**
   * Is preview currently enabled. This might change any given
   * service to use a preview mode. Content being the most obvious one.
   * @returns - A boolean representing preview mode or not.
   */
  public get isPreview(): Nullable<boolean> {
    return this._isPreview;
  }

  public get useBlankNavigation(): boolean {
    return Boolean(this.isPreview && this._useBlankNavigation);
  }

  /**
   * Use a blank version of the navigation. This will speed up page load and is triggered
   * by a query string that is read by a middleware. Only works in the fragment preview.
   */
  public set useBlankNavigation(value: boolean) {
    this._useBlankNavigation = value;
  }

  /**
   * Gets the preview date as a string.
   * @returns - The preview date in UTC string format.
   */
  public get previewDate(): Nullable<Date> {
    return this.isPreview ? this._previewDate : null;
  }

  /**
   * Rests the preview date. Until session service is in place
   * we will have to reset this on every request without a preview date.
   */
  public resetPreviewDate(): void {
    this._previewDate = null;
  }

  public setPreviewDate(date: Date): void;
  public setPreviewDate(dateString: string, formatString: string): void;

  /**
   * This method takes a Date object or two strings.
   * @param date - A string or a Date object.
   * @param formatString - A string used to set the format of the date.
   * @throws - When formatString is passed, but date is not the correct type.
   */
  public setPreviewDate(date?: Date | string, formatString?: string): void {
    if (formatString && typeof date !== 'string') {
      throw new InvalidArgumentError(
        `setPreviewDate was passed a \`formatString\` argument, but \`date\`` +
          ` was not of type \`Date\`. Instead date was"${typeof date}"`
      );
    }

    if (date instanceof Date) {
      this._previewDate = date;
    } else if (typeof date === 'string') {
      if (!formatString) {
        throw new InvalidArgumentError(
          `\`.setPreviewDate()\` was called with a date string, but no \`formatString\`` +
            ` argument was passed to discern the date.`
        );
      }
      this._previewDate =
        dateFromFormattedDateString(date, formatString) ?? null;
    }
  }

  /** @inheritdoc */
  public constructor() {
    super();
    this._isPreview = false;
  }

  /**
   * Enables the preview mode.
   */
  public enablePreview(): void {
    this._isPreview = true;
  }

  /**
   * Disables the preview mode.
   */
  public disablePreview(): void {
    this._isPreview = false;
  }
}

export default new PreviewService();
