/**
 * The possible query types, used by the content api handler and the
 * `CoremediaService` to determine which content shape to get and return to
 * the `ContentService`.
 */
export enum ContentQueryType {
  ID = 'id',
  Tag = 'tag',
  Fragment = 'fragment',
  Generic = 'generic',
  FooterLink = 'footerlink',
  Navigation = 'navigation'
}
