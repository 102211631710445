import { Constructor } from '@/type-utils';
import { mocked } from '@/configs';
import MockService, { MockState, ServiceMock } from '../../MockService';
import Service from '../../../Service';

import { IContentPage } from './coremedia-output-data-types';

import { NAVIGATION_CONTENT } from './mocks/navigationContentMock';
import { PAGE_CONTENT } from './mocks/pageContentMock';
import type { ContentService } from './ContentService';

export const pageContentDTO: IContentPage = {
  navigationContent: NAVIGATION_CONTENT,
  pageContent: PAGE_CONTENT,
  revalidate: 172800
};

const initialState = {};

/**
 * Mock for the CartService.
 */
export default class ContentServiceMock extends ServiceMock<ContentService> {
  private pageContentDTO: IContentPage = pageContentDTO;

  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): ContentService {
    // This is a proxy of the Content service and only contains the public members.
    return MockService.getMockOf(this.service) as unknown as ContentService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<ContentService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.ContentService;
    MockService.mockService(
      mockEnabled,
      service as Constructor<ContentService>,
      {
        getPageContent: async () => {
          return Promise.resolve(this.pageContentDTO);
        },
        getFragmentContent: async () => {
          return Promise.resolve(this.pageContentDTO);
        }
      },
      {},
      this.state
    );
  }
}
